<template>
	<el-dialog :visible.sync="detailDialog" custom-class="decision-dialog" width="60%">
		<div slot="title">
			<span>{{ detailTitle }}</span>
			<span class="data-time">数据时间：{{ startTime }} 至 {{ endTime }}</span>
		</div>
		<div class="decision-detail">
			<div class="my-echarts" id="myEcharts"></div>
			<jy-table max-height="595" :data="tableList">
				<jy-table-column prop="routeDirection" label="方向" min-width="80">
					<template v-slot="{ row }">
						{{ goTypeMap[row.routeDirection] || "-" }}
					</template>
				</jy-table-column>
				<jy-table-column prop="siteCode" label="站点编码" min-width="100"></jy-table-column>
				<jy-table-column prop="siteName" label="站点名称" min-width="120"></jy-table-column>
				<jy-table-column prop="passengerSum" label="客流数据" min-width="100"></jy-table-column>
				<jy-table-column prop="smartSuggestion" label="智能建议" min-width="80">
					<template v-slot="{ row }">
						{{ suggetionMap[row.smartSuggestion] || "-" }}
					</template>
				</jy-table-column>
			</jy-table>
		</div>
		<!-- <div slot="footer">
			<el-button @click="detailDialog = false">关闭</el-button>
		</div> -->
	</el-dialog>
</template>

<script>
import dayjs from "dayjs";

export default {
	data() {
		return {
			tableList: [],
			startTime: "",
			endTime: "",
			goTypeMap: {
				1: "上行",
				2: "下行",
				3: "环形"
			},
			suggetionMap: {
				1: "删除",
				0: "保留"
			},
			myOptions: {
				color: ["#61a5e8", "#7ecf51"],
				tooltip: {
					trigger: "axis"
				},
				legend: {
					show: false
				},
				grid: {
					left: 0,
					right: 20,
					bottom: 20,
					containLabel: true
				},
				xAxis: {
					type: "category",
					boundaryGap: false,
					data: []
				},
				yAxis: {
					type: "value",
					name: "人",
					axisLine: {
						show: false
					},
					axisTick: {
						show: false
					}
				},
				series: [
					{
						name: "上行",
						type: "line",
						stack: "Total",
						/* lineStyle: {
							color: "#61a5e8"
						}, */
						data: []
					},
					{
						name: "下行",
						type: "line",
						stack: "Total",
						lineStyle: {
							type: "dashed"
						},
						data: []
					}
				]
			}
		};
	},
	props: {
		value: Boolean,
		rowData: {
			type: Object,
			default: () => ({})
		}
	},
	computed: {
		detailDialog: {
			get() {
				return this.value;
			},
			set(val) {
				this.$emit("input", val);
			}
		},
		detailTitle() {
			return `${this.rowData.routeName}站点建议详情`;
		}
	},
	mounted() {
		this.initData();
	},
	methods: {
		initData() {
			this.startTime = dayjs(this.rowData.dataTime).startOf("month").format("YYYY-MM-DD");
			this.endTime = dayjs(this.rowData.dataTime).endOf("month").format("YYYY-MM-DD");
			const params = {
				routeId: this.rowData.routeId,
				dataTime: this.rowData.dataTime,
				pageNum: 1,
				pageSize: 99
			};
			console.log(params);
			const url = `/crrc-palm-bus/intelligentDecision/detail`;
			this.$http.post(url, params, { baseType: "base" }).then(res => {
				this.tableList = res?.records || [];
				this.total = res?.total || 0;
				this.formatEcharts();
			});
		},
		formatEcharts() {
			const siteList = this.tableList.map(item => item.siteName);
			const upList = this.tableList.filter(item => item.routeDirection == 1);
			const downList = this.tableList.filter(item => item.routeDirection == 2);
			const siteNumObj = siteList.reduce(
				(res, cur) => {
					const upValue = upList.find(item => item.siteName === cur)?.passengerSum || 0;
					const downValue = downList.find(item => item.siteName === cur)?.passengerSum || 0;
					res.upList.push(upValue);
					res.downList.push(downValue);
					return res;
				},
				{ upList: [], downList: [] }
			);
			this.$nextTick(() => {
				const echartsDom = document.getElementById("myEcharts");
				const myEcharts = echarts.init(echartsDom);
				this.myOptions.xAxis.data = siteList;
				this.myOptions.series[0].data = siteNumObj.upList;
				this.myOptions.series[1].data = siteNumObj.downList;
				myEcharts.setOption(this.myOptions);
			});
		}
	}
};
</script>

<style lang="scss" scoped>
.decision-dialog {
	.data-time {
		display: inline-block;
		margin-left: 50px;
	}
	.decision-detail {
		.my-echarts {
			height: 450px;
			margin-bottom: 20px;
		}
	}
}
</style>
