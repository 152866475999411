<template>
	<div class="dsm-container">
		<jy-query ref="searchForm" :model="searchForm">
			<jy-query-item prop="routeName" label="线路名称:">
				<el-input placeholder="请输入" v-model="searchForm.routeName"></el-input>
			</jy-query-item>
			<jy-query-item label="所属机构:" prop="orgName">
				<div @click="selectInstitutions">
					<el-input placeholder="请选择" v-model="searchForm.orgName" readonly></el-input>
				</div>
			</jy-query-item>
			<jy-query-item prop="dataTime" label="月份:">
				<el-date-picker v-model="searchForm.dataTime" type="month" placeholder="选择月" value-format="yyyy-MM"> </el-date-picker>
			</jy-query-item>
			<template slot="search">
				<el-button type="primary" @click="searchHandle" v-if="true || btnexist('scheduleStatisticalAnalysisSmartDecisionList')"
					>查询</el-button
				>
				<el-button
					type="primary"
					plain
					@click="resetForm('searchForm')"
					v-if="btnexist('scheduleStatisticalAnalysisSmartDecisionReset')"
					>重置</el-button
				>
			</template>
		</jy-query>
		<jy-table max-height="595" ref="jyTable" :data="tableList">
			<jy-table-column type="index" width="60" label="序号" fixed="left" align="center"></jy-table-column>
			<jy-table-column prop="routeCode" label="线路编号" min-width="100"></jy-table-column>
			<jy-table-column prop="routeName" label="线路名称" min-width="160"></jy-table-column>
			<jy-table-column prop="routeType" label="线路类型" min-width="100">
				<template v-slot="{ row }">
					{{ goTypeMap[row.routeType] || "-" }}
				</template>
			</jy-table-column>
			<jy-table-column prop="orgName" label="所属机构" min-width="200"></jy-table-column>
			<jy-table-column prop="upCount" label="原上行/环形站点数" min-width="120"></jy-table-column>
			<jy-table-column prop="downCount" label="原下行站点数" min-width="100"> </jy-table-column>
			<jy-table-column prop="suggestUpCount" label="建议上行/环形站点数" min-width="120"></jy-table-column>
			<jy-table-column prop="suggestDownCount" label="建议下行站点数" min-width="100"> </jy-table-column>
			<jy-table-column prop="dataTime" label="数据时间" min-width="160"> </jy-table-column>
			<jy-operate :list="operateList" width="100"></jy-operate>
		</jy-table>
		<jy-pagination
			@size-change="handleSizeChange"
			@current-change="handleCurrentChange"
			:current-page="page.pageNum"
			:page-size="page.pageSize"
			:total="total"
		></jy-pagination>
		<institutions-tree ref="institutions" @addInstitutions="addInstitutions" title="选择所属机构"></institutions-tree>
		<detail-dialog v-if="showDetailDialog" v-model="showDetailDialog" :rowData="rowData"></detail-dialog>
	</div>
</template>

<script>
import institutionsTree from "@/components/pages/admin/common/dialog-tree/institutions_tree";
import detailDialog from "./detail.vue";
import { btnMixins } from "@/common/js/button.mixin";

export default {
	data() {
		return {
			searchForm: {
				routeName: "",
				orgName: "",
				orgId: "",
				dataTime: ""
			},
			tableList: [{ id: 1 }],
			page: {
				pageSize: 10,
				pageNum: 1
			},
			total: 0,
			operateList: [
				{
					name: "详情",
					fun: this.viewDetail,
					isShow: () => {
						return this.btnexist("scheduleStatisticalAnalysisSmartDecisionDetail");
					}
				}
			],
			rowData: {},
			showDetailDialog: false,
			btnMenuId: "scheduleStatisticalAnalysisSmartDecision",
			goTypeMap: {
				0: "上下行",
				1: "环行"
			}
		};
	},
	components: {
		institutionsTree,
		detailDialog
	},
	mixins: [btnMixins],
	created() {
		this.getTableList();
	},
	methods: {
		getParams() {
			// const [startTime, endTime] = this.searchForm.time || [];
			const params = {
				...this.searchForm,
				...this.page
				/* startTime,
				endTime */
			};
			return params;
		},
		async getTableList() {
			const params = this.getParams();
			const url = `/crrc-palm-bus/intelligentDecision/list`;
			this.$http.post(url, params, { baseType: "base" }).then(res => {
				this.tableList = res?.records || [];
				this.total = res?.total || 0;
			});
		},
		searchHandle() {
			this.page.pageNum = 1;
			this.getTableList();
		},
		// 重置
		resetForm(formName) {
			this.$refs[formName].resetFields();
			this.searchForm.orgId = "";
			this.page.pageNum = 1;
			this.getTableList();
		},
		// 所属机构
		addInstitutions(data) {
			console.log(data);
			this.searchForm.orgId = data.orgId;
			this.searchForm.orgName = data.orgNa;
		},
		// 组织
		selectInstitutions() {
			this.$refs.institutions.init();
		},
		viewDetail(row) {
			this.rowData = row;
			this.showDetailDialog = true;
		},
		// 分页
		handleSizeChange(val) {
			this.page.pageNum = 1;
			this.page.pageSize = val;
			this.getTableList();
		},
		handleCurrentChange(val) {
			this.page.pageNum = val;
			this.getTableList();
		}
	}
};
</script>
